<template>
  <div class="fluid" id="activityList">
    <div>
      <div class="activity-list-div">
        <v-card class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h6 class="indigo--text" style="font-weight: bold; margin: auto">
                LIST AKTIVITAS
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  v-if="wWidth > 768"
                  class="activity-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <router-link to="/hr/activity/submission">
                    <v-btn
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold"
                      style="font-size: 12px"
                      :disabled="loading"
                    >
                      Check In
                    </v-btn>
                  </router-link>

                  <div>
                    <v-btn
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold"
                      style="font-size: 12px; margin-left: 10px"
                      @click="exportData"
                    >
                      Export Data
                    </v-btn>
                  </div>
                </v-toolbar>
                <v-toolbar
                  class="activity-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <div style="width: 150px">
                    <v-select
                      :item-disabled="modeDisable"
                      label="Tipe Kunjungan"
                      v-model="paramAPI.mode"
                      :items="dropdown.user_type"
                      style="position: relative; top: 15px; font-size: 12px"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      @change="modeWatcher"
                    ></v-select>
                  </div>

                  <div style="width: 150px; margin-left: 10px">
                    <v-select
                      @change="companyWatcher"
                      v-model="paramAPI.company_id"
                      :items="dropdown.company"
                      style="
                        position: relative;
                        top: 15px;
                        margin-left: 5px;
                        font-size: 12px;
                      "
                      label="Company"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                      :disabled="getUserProfile.employee.company.plant_id != 6"
                    ></v-select>
                  </div>
                  <!-- <div style="width: 150px; margin-left:20px;">
                    <v-select
                      label="Status"
                      v-model="paramAPI.status_id"
                      :items="dropdown.status"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      clearable
                      outlined
                    ></v-select>
                  </div> -->
                  <!-- <div
                    class="activity-list-toolbar-1"
                    style="width: 150px; margin-left:20px;"
                  >
                    <v-select
                      label="Jenis Izin"
                      v-model="paramAPI.type_id"
                      :items="dropdown.permission_type"
                      style="
                        position: relative;
                        top: 15px;
                        font-size: 12px;
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      clearable
                      outlined
                    ></v-select>
                  </div> -->
                  <div
                    class="activity-list-toolbar-1"
                    style="margin-left: 20px; margin-right: 20px"
                  >
                    <div>
                      <v-text-field
                        outlined
                        class="attendance-start-date ml-1 mr-2"
                        dense
                        label="Start Date"
                        type="date"
                        name="startdate"
                        style="height: 38px"
                        step="1"
                        v-model="start_date"
                        @change="startDateWatcher"
                        clearable
                      />
                    </div>
                    <div style="margin-right: 10px">
                      <v-text-field
                        style="height: 38px"
                        outlined
                        class="attendance-start-date ml-1 mr-2"
                        dense
                        label="End Date"
                        type="date"
                        name="enddate"
                        step="1"
                        v-model="end_date"
                        @change="endDateWatcher"
                        clearable
                      />
                    </div>
                  </div>
                  <div class="activity-list-toolbar-1" style="width: 300px">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      label="Cari disini"
                      type="search"
                      outlined
                      dense
                      append-icon="mdi-magnify"
                      style="position: relative; top: 15px; margin-left: 10px"
                      @keyup.enter="searchEnter"
                      :disabled="loading"
                    ></v-text-field>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar class="activity-list-toolbar-2" flat>
                <v-text-field
                  class="attendance-start-date"
                  dense
                  outlined
                  label="Start Date"
                  type="date"
                  name="startdate"
                  style="height: 30px"
                  step="1"
                  v-model="start_date"
                  @change="startDateWatcher"
                  clearable
                />
              </v-toolbar>
              <v-toolbar
                class="activity-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  class="attendance-start-date"
                  dense
                  outlined
                  label="End Date"
                  type="date"
                  name="enddate"
                  style="height: 30px"
                  step="1"
                  v-model="end_date"
                  @change="endDateWatcher"
                  clearable
                />
              </v-toolbar>
              <v-toolbar
                class="activity-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  v-model="paramAPI.keyword"
                  label="Cari disini"
                  type="search"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  @keyup.enter="searchEnter"
                  :disabled="loading"
                  style="position: relative; top: 15px; width: 10px"
                ></v-text-field>
              </v-toolbar>
            </v-col>

            <v-col cols="12" v-if="wWidth < 769">
              <router-link to="/hr/activity/submission">
                <v-btn
                  small
                  outlined
                  elevation="1"
                  color="indigo"
                  class="indigo--text font-weight-bold"
                  style="font-size: 12px; width: 100%; height: 40px"
                  :disabled="loading"
                >
                  Check In
                </v-btn>
              </router-link>
            </v-col>
            <v-col cols="12" v-if="wWidth < 769" style="margin-bottom: 13px">
              <v-btn
                small
                elevation="1"
                color="indigo"
                class="white--text"
                style="font-size: 12px; width: 100%; height: 40px"
                @click="exportData"
              >
                Export Data
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.check_in_at`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.check_in_at) }}
                  </div>
                </template>
                <template v-slot:[`item.check_out_at`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.check_out_at) }}
                  </div>
                </template>
                <template v-slot:[`item.location`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    <a
                      :href="`https://www.google.com/maps/search/?api=1&query=${item.lt_loc},${item.lg_loc}`"
                      target="_blank"
                      style="font-size: 12px"
                    >
                      {{ item.lt_loc }},{{ item.lg_loc }}
                    </a>
                    <!-- / <a
                            :href="
                              `https://www.google.com/maps/search/?api=1&query=${item.lt_loc},${item.lg_loc}`
                            "
                            target="_blank"
                            style="font-size:12px;"
                          >
                            Out</a
                          > -->
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'activity',
  data: () => ({
    wWidth: window.innerWidth,
    dialog: false,
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'name',
      sortType: 'asc',
      company_id: null,
      department_id: null,
      status_id: null,
      type_id: null,
      date: '',
      user_type_id: 0,
      mode: 1
    },
    totalData: 0,

    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    actionValue: 0,
    headers: [
      {
        text: 'NIK',
        value: 'nik',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'employee_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Customer',
        value: 'odoo_customer_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Code',
        value: 'odoo_customer_code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Bertemu dengan',
        value: 'meet_with',
        align: 'left',
        sortable: false
      },
      {
        text: 'Check In',
        value: 'check_in_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Check Out',
        value: 'check_out_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Location',
        value: 'location',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    dropdown: {
      company: [],
      status: [],
      department: [],
      type: [],
      user_type: [],
      permission_type: []
    },
    start_date: '',
    end_date: ''
  }),

  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.paramAPI.department_id = this.getUserProfile.employee.department_id
    if (this.getUserProfile.level.find(({ id }) => id === '39') !== undefined) {
      this.paramAPI.mode = ''
    }

    this.userLevel = this.getUserProfile.level.find(
      ({ application }) => application.id === 1 || application.id === 21
    )

    this.setOptionDate()
    await this.getDataFromApi()
    await this.initDropdown()
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile'])
  },
  watch: {
    // async 'paramAPI.company_id'() {
    //   await this.getDataFromApi()
    // },
    // async 'paramAPI.mode'() {
    //   await this.getDataFromApi()
    // }
    // async 'paramAPI.status_id'() {
    //   await this.getDataFromApi()
    // },
    // async 'paramAPI.type_id'() {
    //   await this.getDataFromApi()
    // }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    // ...mapActions(['dropdownPlant']),
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    setOptionDate() {
      const d = new Date()
      d.setDate(d.getDate())
      const n = d.toLocaleDateString('id').split('/').reverse()
      if (n[1].length === 1 || n[2].length === 1) {
        if (n[1].length === 1) {
          n[1] = `0${n[1]}`
        }
        if (n[2].length === 1) {
          n[2] = `0${n[2]}`
        }
      }
      const joinedDate = n.join('-')
      const sDate = joinedDate
      const sDateSplit = sDate.split('-')
      sDateSplit[2] = '01'
      this.start_date = sDateSplit.join('-')
      this.end_date = joinedDate
    },
    modeWatcher() {
      this.getDataFromApi()
    },
    async companyWatcher(p) {
      this.paramAPI.company_id = p

      await this.getDataFromApi()
    },
    startDateWatcher() {
      if (this.start_date === null) {
        this.start_date = ''
        this.end_date = ''
      }
      this.getDataFromApi()
    },
    endDateWatcher() {
      if (this.end_date === null) {
        this.start_date = ''
        this.end_date = ''
      }
      this.getDataFromApi()
    },
    dropdownUserType() {
      if (
        this.getUserProfile.level.find(
          ({ id }) => id === '39' || id === '42'
        ) !== undefined
      ) {
        this.dropdown.user_type = [
          {
            id: '',
            name: 'Semuanya'
          },
          {
            id: 1,
            name: 'Pribadi'
          },
          // {
          //   id: 2,
          //   name: 'Lain-lain'
          // },
          {
            id: 4,
            name: 'Bawahan'
          }
        ]
      } else {
        this.dropdown.user_type = [
          {
            id: 1,
            name: 'Pribadi'
          },
          // {
          //   id: 2,
          //   name: 'Lain-lain'
          // },
          {
            id: 4,
            name: 'Bawahan'
          }
        ]
      }
    },
    // dropdownStatus() {
    //   this.dropdown.status = [
    //     {
    //       id: '-2',
    //       name: 'Canceled'
    //     },
    //     {
    //       id: '-1',
    //       name: 'Rejected'
    //     },
    //     {
    //       id: '0',
    //       name: 'Draft'
    //     },
    //     {
    //       id: '1',
    //       name: 'Waiting Approval'
    //     },
    //     {
    //       id: '2',
    //       name: 'Approved'
    //     }
    //   ]
    // },
    async initDropdown() {
      this.dropdownUserType()
      // this.dropdownStatus()
      // this.dropdownPermissionType()
      this.getDropdownCompany()
      await this.dropdownDepartment()
    },
    searchEnter() {
      this.getDataFromApi()
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then((data) => {
        console.log(data)
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      let startDate = ''
      let endDate = ''
      if (this.start_date !== '') {
        startDate = this.start_date + ' 00:00:00'
      } else {
        startDate = ''
      }
      if (this.end_date !== '') {
        endDate = this.end_date + ' 23:59:59'
      } else {
        endDate = ''
      }

      let url = `${this.hrsApi}salesman_activity/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&start_date=${startDate}&end_date=${endDate}&mode=${this.paramAPI.mode}`

      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      console.log(pItem)
      setTimeout(() => {
        this.$router.push(`/hr/activity/detail/${pItem.id}`)
      }, 100)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async dropdownDepartment() {
      await axios
        .get(`${this.hrsApi}master/universal/department/dropdown`)
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    // async dropdownPermissionType() {
    //   await axios
    //     .get(`${this.hrsApi}master/universal/permissiontype/dropdown`)
    //     .then(res => {
    //       if (res.data.status_code === '00') {
    //         return (this.dropdown.permission_type = res.data.data)
    //       }
    //       return (this.dropdown.permission_type = [])
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       return (this.dropdown.permission_type = [])
    //     })
    // },
    async getDropdownCompany() {
      if (this.userLevel !== null) {
        console.log('getDropdownCompany>>>>>', this.userLevel)
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
      // else {
      //   this.dropdownCompanyApi()
      // }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    },
    // requestStatus(val) {
    //   switch (val) {
    //     case -2:
    //       return 'CANCELED'
    //     case -1:
    //       return 'REJECTED'
    //     case 0:
    //       return 'DRAFT'
    //     case 1:
    //       return 'WAITING APPROVAL'
    //     case 2:
    //       return 'APPROVED'
    //     default:
    //       break
    //   }
    // },
    // statusColor(val) {
    //   switch (val) {
    //     case -2:
    //       return 'red'
    //     case -1:
    //       return 'red'
    //     case 0:
    //       return 'blue'
    //     case 1:
    //       return 'orange'
    //     case 2:
    //       return 'green'
    //     default:
    //       break
    //   }
    // },
    modeDisable(item) {
      if (
        this.getUserProfile.level.find(
          ({ id }) => id === '39' || id === '42'
        ) !== undefined
      ) {
        return false
      } else {
        return item.id === ''
      }
    },
    async exportData() {
      let startDate = ''
      let endDate = ''
      if (this.start_date !== '') {
        startDate = this.start_date + ' 00:00:00'
      }
      if (this.end_date !== '') {
        endDate = this.end_date + ' 23:59:59'
      }
      const url = `${this.hrsApi}salesman_activity/list?keyword=${this.paramAPI.keyword}&offset=0&limit=1000000&start_date=${startDate}&end_date=${endDate}&company_id=${this.paramAPI.company_id}`

      await axios
        .get(url)
        .then((res) => {
          console.log(res)
          let selectedData = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              selectedData.push({
                check_in_at: response[i].check_in_at,
                check_out_at: response[i].check_out_at,
                created_at: response[i].created_at,
                description: response[i].description,
                employee_name: response[i].employee_name,
                id: response[i].id,
                lg_loc: response[i].lg_loc,
                lt_loc: response[i].lt_loc,
                meet_with: response[i].meet_with,
                nik: response[i].nik,
                odoo_customer_address: response[i].odoo_customer_address,
                odoo_customer_code: response[i].odoo_customer_code,
                odoo_customer_name: response[i].odoo_customer_name,
                updated_at: response[i].updated_at
              })
            }
            this.exportNow(selectedData)
          } else {
            selectedData = []
          }
          return null
        })
        .catch((error) => {
          console.log(error)
          return null
        })
    },
    exportNow(selectedData) {
      const arrData = []
      for (let i = 0; i < selectedData.length; i++) {
        const param = {
          // id: selectedData[i].id,
          NIK: selectedData[i].nik,
          Nama: selectedData[i].employee_name,
          'Bertemu Dengan': selectedData[i].meet_with,
          Lokasi: selectedData[i].odoo_customer_name,
          Kode: selectedData[i].odoo_customer_code,
          Alamat: selectedData[i].odoo_customer_address,
          'Check In': selectedData[i].check_in_at,
          inKoordinat: `${selectedData[i].lt_loc},${selectedData[i].lg_loc},`,
          'Check Out': selectedData[i].check_out_at,
          outKoordinat: '',
          Catatan: selectedData[i].description,
          'Dibuat Pada': selectedData[i].created_at,
          'DiUpdate Pada': selectedData[i].updated_at
          // lg_loc: selectedData[i].lg_loc,
          // lt_loc: selectedData[i].lt_loc,
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(
        wb,
        `ACTIVITY_${this.start_date}_to_${this.end_date}${
          '_' + this.paramAPI.keyword
        }.xlsx`
      )
    }
  }
}
</script>
<style lang="scss">
#activityList {
  position: relative;
  .activity-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .activity-list-toolbar-1 {
      .activity-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #activityList {
    .activity-list-div {
      .activity-list-toolbar-1 {
        .activity-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
